import React from "react";


const TeacherUpdate = () =>{
    return(
        <>

        </>
    )
}

export default TeacherUpdate;